import React, { useState, useEffect, createRef, useMemo } from "react";
import axios from "axios";
import MediaQuery from "react-responsive";
import Loader from "@baxus/shared/dist/Components/Loader";
import { USDFormatter } from "@baxus/shared/dist/Utils/Utils";
import AnimtateAssets from "./AnimateAssets";
import FeaturedAsset from "./FeaturedAsset";
import FeaturedMobileAssetCarousel from "./FeaturedMobileAssetCarousel";
import AssetHighlightDetails from "./FeaturedAssetHighlightDetails";
import "../../Styles/FeaturedAssets.styles.scss";
import CarouselSection from "../../Pages/CarouselSection";

const FeaturedAssets = () => {
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const [isAssetSelected, setIsAssetSelected] = useState(false);

  const selectRandomAssets = (array) => {
    const shuffleAssets = [...array];

    for (let i = shuffleAssets.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffleAssets[i], shuffleAssets[j]] = [
        shuffleAssets[j],
        shuffleAssets[i],
      ];
    }
    setAssets(shuffleAssets);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/assets/featured`)
      .then((res) => {
        const featuredAssetsArr = res.data;
        selectRandomAssets(featuredAssetsArr);
      })
      .catch((e) => {
        console.log("Error fetching featured assets", e);
      });

    setLoading(false);
  }, []);

  const reorderAssets = (reorderedAssets) => {
    setAssets(reorderedAssets);
  };

  const selectAsset = (index) => {
    const reorderedAssets = [...assets];

    if (index !== 0) {
      [reorderedAssets[0], reorderedAssets[index]] = [
        reorderedAssets[index],
        reorderedAssets[0],
      ];
    }

    reorderAssets(reorderedAssets);
    setIsAssetSelected(true);
  };

  const renderDesktopAssets = useMemo(() => {
    let curAssets = assets.slice(0, 3);
    return curAssets.map((asset, i) => {
      return (
        <FeaturedAsset
          assetDetails={asset}
          key={asset.BottleID}
          ref={createRef()}
          curIndex={i}
          selectAsset={selectAsset}
          assetSelected={isAssetSelected}
        />
      );
    });
  }, [assets, isAssetSelected]);

  const toggleAssetSelected = () => {
    setIsAssetSelected(!isAssetSelected);
  };

  const renderMobileAssets = () => {
    return (
      <FeaturedMobileAssetCarousel
        assets={assets}
        USDFormatter={USDFormatter}
      />
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="backgroundImage">
            <CarouselSection assets={assets} />
          </div>
          <MediaQuery minWidth={768}>
            <div className="featuredAssetListWrapper">
              {/* <AnimtateAssets>{renderDesktopAssets}</AnimtateAssets> */}
              {isAssetSelected ? (
                <AssetHighlightDetails
                  assetDetails={assets[0]}
                  assetSelected={isAssetSelected}
                  deselectAsset={toggleAssetSelected}
                  USDFormatter={USDFormatter}
                />
              ) : (
                <></>
              )}
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <div className="mobileFeaturedAssetListWrapper">
              {renderMobileAssets()}
            </div>
          </MediaQuery>
        </>
      )}
    </>
  );
};

export default FeaturedAssets;
