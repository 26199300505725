import React, { useState, useEffect } from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const HPSectionCTA = ({ isForm, ctaLink, cta }) => {
  const CustomForm = ({ onValidated, status, message }) => {
    const [statusMessageComponent, setStatusMessageComponent] = useState(<></>)
    let email

    useEffect(() => {
      if (status === "sending") {
        setStatusMessageComponent(
          <p className='HPFormSuccess'>Submitting...</p>
        )
      } else if (status === "success") {
        setStatusMessageComponent(
          <p className='HPFormSuccess'>Subscribed successfully!</p>
        )
      } else if (status === "error") {
        if (message.includes("has too many recent signup requests")) {
          setStatusMessageComponent(
            <p className='HPFormError'>
              Error subscribing, too many recent sign up requests with this
              email.
            </p>
          )
        } else if (message.includes("already subscribed to list")) {
          setStatusMessageComponent(
            <p className='HPFormError'>
              Error subscribing, you are already registered to receive these
              updates.
            </p>
          )
        } else {
          setStatusMessageComponent(
            <p className='HPFormError'>Error subscribing, please try again</p>
          )
        }
      }

      return () => {
        setStatusMessageComponent(<></>)
      }
    }, [status])

    const submit = () => {
      return (
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email.value,
        })
      )
    }

    return (
      <div className='HPFormWrapper'>
        <input
          className='HPFormInput'
          placeholder='Email'
          type='email'
          ref={(node) => (email = node)}
        />
        {statusMessageComponent}
        <button className='servicesCTA' onClick={submit}>
          {cta}
        </button>
      </div>
    )
  }

  return (
    <>
      {isForm ? (
        <MailchimpSubscribe
          url={ctaLink}
          render={({ subscribe, status, message }) => (
            <CustomForm
              onValidated={(formData) => subscribe(formData)}
              status={status}
              message={message}
            />
          )}
        />
      ) : (
        <button className='servicesCTA'>
          <a href={ctaLink} target='_blank' rel='noreferrer'>
            {cta}
          </a>
        </button>
      )}
    </>
  )
}

export default HPSectionCTA
