import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"

const AgeValidation = ({ setAgeConfirmation, isShow }) => {
  const [isAgeNotValid, setIsAgeNotValid] = useState(false)

  useEffect(() => {
    const footer = document.querySelector(".footerWrapper")
    isShow ? (footer.style.display = "none") : (footer.style.display = "flex")
  }, [isShow])

  return (
    <Modal
      backdrop='static'
      centered
      keyboard={false}
      show={isShow}
      size='lg'
      className='ageValidationModalContainer'
    >
      <div className='ageValidationWrapper'>
        <Modal.Body>
          <h1 className='ageValidationHeader'>
            I am of legal drinking age in my location
          </h1>
          <div className='ageValidationCTAs'>
            <button
              className='ageValidationCTA'
              onClick={() => setAgeConfirmation()}
            >
              Confirm
            </button>
            <button
              className='ageValidationCTA'
              onClick={() => setIsAgeNotValid(true)}
            >
              Deny
            </button>
          </div>
          <div className='ageValidationErrorWrapper'>
            {isAgeNotValid && (
              <p className='ageValidationError'>
                You must be of legal drinking age to view this website.
              </p>
            )}
          </div>
          <p className='ageValidationPrivacyAndRes'>
            By entering our website you agree to our{" "}
            <a href='/privacy'>Privacy Policy</a>. For information on alcohol
            responsibility, visit{" "}
            <a href='https://drinkaware.co.uk' target='_blank' rel='noreferrer'>
              drinkaware.co.uk
            </a>
          </p>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default AgeValidation
