import React from "react";
import "./collection.css";

const dummyData = [
  {
    imageUrl: "img/bottle1.png",
  },
  {
    imageUrl: "img/old-rip.png",
  },
  {
    imageUrl: "img/rosebnk.png",
  },
  {
    imageUrl: "img/rothschild.png",
  },
  {
    imageUrl: "img/yamaza.png",
  },
  {
    imageUrl: "img/yamazaki.png",
  },
];

export default function Collection() {
  return (
    <>
      <div className="textHeader container-sm">
        <h1>The Collection</h1>
        <p className="container-sm">
          In a professional context it often happens that private or corporate
          clients corder a publication . to be made and presented . with the
          still not being ready. Think of a news blog that's filled with content
          hourly on the day of going live. However, revie
        </p>
      </div>
      <div className="container">
        <div className="row">
          {dummyData.map((data) => {
            return (
              <>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="collectionBg">
                    <img src={data.imageUrl} alt="" />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
