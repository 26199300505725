import React from "react"

const TermsConditions = () => {
  return (
    <div className='legalPageWrapper container'>
      <div className='legalPageHeaderWrapper'>
        <h1 className='legalPageHeader'>TERMS OF USE</h1>
        <h4 className='legalPageHeader'>Last Updated: 1/2/2022</h4>
      </div>
      <div className='legalPageContent'>
        <p>
          BAXUS CORP. (the “Baxus,” “we,” “us,” or “our”) welcomes you. These
          terms of use (these “Terms of Use”) govern you access to our digital
          marketplace platform and related services that are made available to
          you through Baxus (“Platform”), as well as your access to our website
          located at Baxus.co (the “Site”)
        </p>
        <p>
          By browsing the public areas of the Site and/or by clicking “I AGREE,”
          when you sign up to access and use the Platform, you acknowledge that
          you have read, understood, and agree to be legally bound by the terms
          and conditions of these Terms of Use and the terms and conditions of
          our Privacy Policy, which are hereby incorporated by reference
          (collectively, this “Agreement”). We may update these Terms of Use
          from time to time, and if you accept the modified terms or otherwise
          access or use the Site and/or the Platform after such modified changes
          go into effect, you will be deemed to agree to the modified terms. If
          you do not agree to any of these terms, then you are not permitted to
          use the Site and the Platform.
        </p>
        <p>
          Capitalized terms not defined in these Terms of Use shall have the
          meaning set forth in our Privacy Policy.
        </p>
        <p className='legalPageSectionBolded'>
          THE SECTIONS BELOW TITLED “BINDING ARBITRATION” AND “CLASS ACTION
          WAIVER” CONTAIN A BINDING ARBITRATION AGREEMENT AND CLASS ACTION
          WAIVER. THEY AFFECT YOUR LEGAL RIGHTS. PLEASE READ THEM CAREFULLY.
        </p>
        <h4 className='legalPageSectionHeader'>
          DESCRIPTION AND USE OF OUR PLATFORM
        </h4>
        <p className='legalPageSectionContent'>
          The Platform is a marketplace where people can buy, sell and trade
          high-end whiskeys/liquors in the form of non-fungible tokens (“NFTs”).
          These are called “Collectible NFT Assets”. Sellers of Collectible NFT
          Assets are called “Seller,” and Purchasers of Collectible NFT Assets
          are called “Collectors.” Sellers and Collectors store their physical
          assets in one of our secure, temperature controlled vaults and we
          tokenize the stored assets into NFTs. Each NFT represents ownership in
          the actual physical asset it corresponds to. When users are ready to
          collect their assets, the NFTs are destroyed and the assets are safely
          returned or otherwise made available at our applicable vault.
        </p>
        <p className='legalPageSectionContent'>
          Each Collectible NFT Asset offered on the Platform is an NFT on the
          Solana blockchain network (the “Solana Network”). We provide Visitors,
          and Registered Users with access to the Site and the Platform as
          described below:
        </p>
        <p className='legalPageSectionContent'>
          Visitors. Visitors, as the term implies, are people who do not
          register with us, but want to explore the Site. No login is required
          for Visitors. Visitors can: (i) view all publicly-available content on
          the Site; (ii) subscribe to communications; and/or (iii) e-mail us.
        </p>
        <p className='legalPageSectionContent'>
          To become a Seller or a Collector, you must register to use the
          Platform (“Registered Users”). Registered Users can do all the things
          that Visitors can do, as well as access and use password-protected
          areas of the Platform, and buy, sell, and trade Collectible NFT
          Assets. We are under no obligation to accept any individual as a
          Registered User and may accept or reject any registration in our sole
          and complete discretion. In addition, we may, but are not required to,
          deactivate any account at any time, including, without limitation, if
          we determine that a Registered User has violated these Terms of Use.
        </p>
        <h4 className='legalPageSectionHeader'>
          MARKETPLACE - BUYING AND SELLING COLLECTIBLE NFT ASSETS Buying and
          Owning a Collectible NFT Asset
        </h4>
        <p className='legalPageSectionContent'>
          The Platform is a first-party marketplace, where Sellers can sell
          Collectible NFT Assets, and Collectors can buy Collectible NFT Assets
          from such Sellers. Our Platform enables the initial minting, claiming,
          and purchasing of Collectible NFT Assets. Purchases made on the
          Platform will be subject to a transaction fee. When you purchase a
          Collectible NFT Asset on the Platform, you become the owner of the
          Collectible NFT Asset, including the NFT authenticating and evidencing
          your ownership in such Collectible NFT Asset.
        </p>
        <p className='legalPageSectionContent'>
          As a Collector, you agree that you shall not, nor permit any third
          party to, do or attempt to do any of the following without our express
          prior written consent in each case: (a) use the Collectible NFT Asset
          to advertise, market, or sell any third-party product or service; (b)
          sell, distribute for commercial gain (including, without limitation,
          giving away in the hopes of eventual commercial gain), or otherwise
          commercialize merchandise that includes, contains, or consists of the
          Collectible NFT Asset; (c) attempt to trademark, copyright, or
          otherwise acquire additional intellectual property rights in or to the
          Collectible NFT Asset; or (d) otherwise utilize the Collectible NFT
          Asset for your or any third party's commercial benefit.
        </p>
        <p className='legalPageSectionContent'>
          In addition, Collectors acknowledge that additional restrictions on
          use of the Collectible NFT Asset may be set by us and/or the Seller
          and determined at the “smart contract” level in the Solana Network.
          All of the foregoing restrictions and any additional restrictions set
          at the “smart contract” level shall apply perpetually, including in
          the event of a secondary sale of the Collectible NFT Asset.
        </p>
        <h4 className='legalPageSectionHeader'>
          Trading Collectible NFT Assets
        </h4>
        <p className='legalPageSectionContent'>
          In order to move Collectible NFT Assets off of our Platform, you will
          need to use a supported electronic wallet that is Solana Network
          compatible (a “Wallet”). You may purchase Collectible NFT Assets on
          our Platform without a Wallet, but without a Wallet you will not be
          able to move the Collectible NFT Assets off the Platform. You can use
          your Wallet to purchase, store, and engage in transactions using a
          credit card, or via one or more cryptocurrencies that we may elect to
          accept from time to time.
        </p>
        <h4 className='legalPageSectionHeader'>
          All Transactions on the Platform
        </h4>
        <p className='legalPageSectionContent'>
          Transactions that take place on the Platform are managed and confirmed
          via the Solana Network. You understand that your Solana Network public
          address will be made publicly visible whenever you engage in a
          transaction on the Platform. Further, you understand that we have no
          control over payments or transactions occurring on the Solana Network,
          nor do we have the ability to reverse or refund any payments or
          transactions. We have no liability to you, or to any third party, for
          any claims or damages that may arise as a result of any payments or
          transactions that you engage in via the Platform, or any other payment
          or transactions that you conduct via the Solana Network.
        </p>
        <p className='legalPageSectionContent'>
          There are certain risks associated with the use, purchase, and sale of
          NFTs. Please review Section 10 of these Terms of Use for more
          information about such risks.
        </p>
        <h4 className='legalPageSectionHeader'>PAYMENT AND FEES</h4>
        <p className='legalPageSectionContentBullet'>
          • Transaction Fees. Sellers shall collect payment through the sale of
          the Collectible NFT Assets. Sale price for each Collectible NFT Asset
          is set by Seller; Baxus does not set the price of sale for Collectible
          NFT Assets. We charge a transaction fee for use of the Platform, which
          is currently _____ of proceeds on sales made via our Platform (the
          “Transaction Fees”). Our transaction fees are collected at the time of
          sale, as applicable.
        </p>
        <p className='legalPageSectionContentBullet'>
          • Minting Fees. We do not currently charge any fees for the minting
          NFTs (“Minting Fees”). We reserve the rights to charge Minting Fees in
          the future, as required by our third-party vendors or in our sole
          discretion at any time, and in such event the Minting Fees will be
          paid up front at the time of minting, regardless of whether the Seller
          sells any Collectible NFT Assets through the Platform, and all Minting
          Fees will be in addition to other fees charged hereunder. We will use
          commercially reasonable efforts to notify Sellers of the
          implementation of any Minting Fees in advance of their effect.
        </p>
        <p className='legalPageSectionContentBullet'>
          • No Refunds. All fees charged by us hereunder are non-refundable,
          except as determined by us in our sole discretion. We reserve the
          right to change our fees or add additional fees at any time.
        </p>
        <p className='legalPageSectionContentBullet'>
          • Blockchain Transaction Processing. All blockchain-based financial
          transactions via the Platform will be conducted through the Solana
          Network. You warrant and represent that you are the valid owner or an
          authorized user, of the credit card or payment account that you
          provide through the Solana Network, and that all information you
          provide is accurate. We have no control over these payments or
          transactions, nor do we have the ability to reverse or refund any
          payments or transactions. We have no liability to you, or to any third
          party for any claims or damages that may arise as a result of any
          payments or transactions that you engage in via the Platform, or any
          other payment or transactions that you conduct via the Solana Network.
        </p>
        <p className='legalPageSectionContentBullet'>
          • Taxes. You will be solely responsible to pay any and all taxes,
          duties, and assessments (except taxes on our net income) now or
          hereafter claimed or imposed by any governmental authority
          (collectively, the “Taxes”) associated with your use of the Platform.
          By agreeing to these Terms of Use, you agree you: (a) will pay or
          reimburse us for all taxes and assessments of any jurisdiction,
          including value added taxes and taxes as required by international tax
          treaties, customs or other import or export taxes, and amounts levied
          in lieu thereof based on charges set, services performed or payments
          made hereunder, as are now or hereafter may be imposed under the
          authority of any national, state, local or any other taxing
          jurisdiction; and (b) will not be entitled to deduct the amount of any
          such taxes, duties or assessments from payments (including Minting
          Fees) made to us pursuant to these Terms of Use.
        </p>
        <h4 className='legalPageSectionHeader'>COMMUNITY GUIDELINES</h4>
        <p className='legalPageSectionContent'>
          By accessing and/or using the Site and the Platform, you agree to
          comply with the following community guidelines (the “Community
          Guidelines”):
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will only access the Site and Platform if you are age thirteen
          (13) or over; users under age eighteen (18) must have permission from
          a parent or guardian to access and use the Site and the Platform, and
          a parent or guardian must accept these Terms of Use on behalf of the
          minor;
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will comply with all applicable laws relating to your use of the
          Site and the Platform and will not use the Site and the Platform for
          any unlawful, deceptive, or fraudulent purpose;
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not upload, post, e-mail, transmit, or otherwise make
          available any Registered User Content that:
        </p>
        <p className='legalPageSectionContentBullet'>
          • infringes any copyright, trademark, right of publicity, or other
          proprietary rights of any person or entity; or
        </p>
        <p className='legalPageSectionContentBullet'>
          • constitutes promotion or advertising of any third-party website,
          product or service; or
        </p>
        <p className='legalPageSectionContentBullet'>
          • is defamatory, libelous, indecent, obscene, pornographic, sexually
          explicit, invasive of another's privacy, promotes violence, or
          contains hate speech (i.e., speech that attacks or demeans a group
          based on race or ethnic origin, religion, disability, gender, age,
          veteran status, and/or sexual orientation/gender identity); or
        </p>
        <p className='legalPageSectionContentBullet'>
          • discloses any sensitive information about another person, including
          that person's e-mail address, postal address, phone number, credit
          card information, or any similar information.
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not “stalk,” threaten, or otherwise harass another person;
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not access or use the Site and the Platform to collect any
          market research for a competing business;
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not impersonate any person or entity or falsely state or
          otherwise misrepresent your affiliation with a person or entity;
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not interfere with or attempt to interrupt the proper
          operation of the Site and the Platform through the use of any virus,
          device, information collection or transmission mechanism, software or
          routine, or access or attempt to gain access to any data, files, or
          passwords related to the Site and the Platform through hacking,
          password or data mining, or any other means;{" "}
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not cover, obscure, block, or in any way interfere with any
          advertisements and/or safety features (e.g., report abuse button) on
          the Site and the Platform;
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not use any robot, spider, scraper, or other automated
          means to access the Site and the Platform for any purpose without our
          express written permission; provided, however, we grant the operators
          of public search engines permission to use spiders to copy materials
          from the public portions of the Site and the Platform for the sole
          purpose of and solely to the extent necessary for creating
          publicly-available searchable indices of the materials, but not caches
          or archives of such materials;
        </p>
        <p className='legalPageSectionContentBullet'>
          • To the extent you utilize any robot, spider, scraper, or other
          automated means to access the Site and the Platform in violation of
          the foregoing, you hereby allow us to, with or without notice to you,
          employ any technical safeguards or other means to block such
          activities, including, without limitation, blocking your access to the
          Site and/or Platform entirely.
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not take any action that imposes or may impose (in our sole
          discretion) an unreasonable or disproportionately large load on our
          technical infrastructure;
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will let us know about inappropriate Registered User Content of
          which you become aware; and
        </p>
        <p className='legalPageSectionContentBullet'>
          • You will not take any action that otherwise involves or results in
          the wrongful seizure or receipt of any NFTs or other digital assets,
          including purchase or sale of the NFTs by illegal means.
        </p>
        <p className='legalPageSectionContent'>
          We have the right, but not the obligation, to review and reject or
          remove content that, in our sole discretion, does not adhere to these
          Community Guidelines. We also reserve the right, in our sole and
          absolute discretion, to deny you access to the Site and the Platform,
          or to any portion of the Site and the Platform, for any reason with or
          without notice. We may, at our sole and absolute discretion, without
          notice or liability to you, and without limiting any of our other
          rights or remedies at law or in equity, immediately suspend or
          terminate your user account and/or, if you are a Seller, delete your
          unsold Collectible NFT Assets from the Platform.
        </p>
        <h4 className='legalPageSectionHeader'>
          SIGN-IN NAME; PASSWORD; UNIQUE IDENTIFIERS
        </h4>
        <p className='legalPageSectionContent'>
          If you wish to become a Registered User, you will be prompted to
          create an account, which will require a sign-in name (“Sign-In Name”),
          a password (“Password”), and perhaps certain additional information
          that will assist in authenticating your identity when you log-in in
          the future (“Unique Identifiers”). When creating your account, you
          must provide true, accurate, current, and complete information. Each
          Sign-In Name and corresponding Password can be used by only one
          Registered User. You are solely responsible for the confidentiality
          and use of your Sign-In Name, Password, and Unique Identifiers, as
          well as for any use, misuse, or communications entered or payments
          made through the Platform using one or more of them. You will promptly
          inform us of any need to deactivate a Password or Sign-In Name, or
          change any Unique Identifier. We reserve the right to delete or change
          your Password, Sign-In Name, or Unique Identifier at any time and for
          any reason and shall have no liability to you for any loss or damage
          caused by such action. We will not be liable for any loss or damage
          caused by any unauthorized use of your account.
        </p>
        <h4 className='legalPageSectionHeader'>INTELLECTUAL PROPERTY</h4>
        <p className='legalPageSectionContent'>
          Apart from the NFTs, the Site and the Platform may contain material,
          such as software, text, graphics, images, designs, sound recordings,
          audiovisual works, and other material provided by or on behalf of us
          (collectively referred to as the “Baxus Content”). You shall not (i)
          sell, transfer, assign, license, sublicense, or modify the Site, the
          Platform or any Baxus Content; (ii) reproduce, display, publicly
          perform, make a derivative version of, distribute, reverse engineer or
          otherwise attempt to discover the source code of any software
          contained in or relating to the Site, the Platform or any Baxus
          Content; or (iii) otherwise use the Site, the Platform or any Baxus
          Content in any way for any public or commercial purpose or in any
          manner other than as expressly permitted in these Terms of Use. We
          retain all right, title and interest and in and to (i) the Site, the
          Platform and all Baxus Content; (ii) all improvements and
          modifications to, and derivative works of, the Site, the Platform and
          all Baxus Content; and (iii) all intellectual property rights relating
          to the foregoing. You do not obtain any rights to the Site, the
          Platform or any Baxus Content, except for the limited right to access
          and use the Site, the Platform as expressly permitted herein.
        </p>
        <p className='legalPageSectionContent'>
          The trademarks, service marks, and logos of Baxus (the “Baxus
          Trademarks”) used and displayed on the Site and the Platform are
          registered and unregistered trademarks or service marks of Baxus.
          Other company, product, and service names located on the Site and the
          Platform may be trademarks or service marks owned by others (the
          “Third-Party Trademarks,” and, collectively with Baxus Trademarks, the
          “Trademarks”) used either under license, or for descriptive or
          nominative purpose. Nothing on the Site and the Platform should be
          construed as granting, by implication, estoppel, or otherwise, any
          license or right to use the Trademarks, without our prior written
          permission specific for each such use. Use of the Trademarks as part
          of a link to or from any website is prohibited unless establishment of
          such a link is approved in advance by us in writing. All goodwill
          generated from the use of Baxus Trademarks inures to our benefit.
        </p>
        <p className='legalPageSectionContent'>
          Elements of the Site and the Platform are protected by trade dress,
          trademark, unfair competition, and other state and federal laws and
          may not be copied or imitated in whole or in part, by any means,
          including, but not limited to, the use of framing or mirrors. None of
          the Content may be retransmitted without our express, written consent
          for each and every instance.
        </p>
        <h4 className='legalPageSectionHeader'>REDEMPTION AND SHIPPING</h4>
        <p className='legalPageSectionContent'>
          The owner of each NFT may redeem the digital asset to the physical
          asset. We will process the redemption only if the redeemer is in
          compliance of the drinking age in their local jurisdiction. All
          redemptions are final and may not be reversed.
        </p>
        <p className='legalPageSectionContent'>
          BY SENDING A REDEMPTION REQUEST, YOU REPRESENT AND WARRANT TO US THAT
          YOU ARE OVER THE LEGAL AGE REQUIRED TO BUY ALCOHOLIC BEVERAGES AND
          PRODUCTS IN YOUR LOCAL JURISDICTION AND YOU ALSO AFFIRM THAT THE
          STATE/COUNTRY IN WHICH YOU ARE ASKING US TO DELIVER PERMIT THE SALE
          AND DELIVERY.
        </p>
        <p className='legalPageSectionContent'>
          Once you redeem your Collectible NFT Asset, we will ship and/or
          arrange for shipping of the physical asset to the shipping address
          that you provide. By redeeming your Collective NFT Asset, you
          represent and warrant that you are complying with all applicable laws
          and regulations that apply to you with respect to such purchase,
          shipping and delivery of alcoholic beverages.
        </p>
        <h4 className='legalPageSectionHeader'>COMMUNICATIONS WITH US</h4>
        <p className='legalPageSectionContent'>
          Although we encourage you to e-mail us, we do not want you to, and you
          should not, e-mail us any content that contains confidential
          information. With respect to all e-mails and communications you send
          to us, including, but not limited to, feedback, questions, comments,
          suggestions, and the like, we shall be free to use any ideas,
          concepts, know-how, or techniques contained in your communications for
          any purpose whatsoever, including but not limited to, the development,
          production, and marketing of products and services that incorporate
          such information without compensation or attribution to you.
        </p>
        <h4 className='legalPageSectionHeader'>
          DISCLAIMERS; LIMITATIONS OF LIABILITY
        </h4>
        <p className='legalPageSectionContent'>
          THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
          WITHOUT ANY WARRANTIES OF ANY KIND, INCLUDING THAT THE PLATFORM WILL
          OPERATE ERROR-FREE, THAT THE PLATFORM, THE SERVERS, OR THE CONTENT ARE
          FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE
          FEATURES. WE HEREBY DISCLAIM ANY AND ALL WARRANTIES RELATING TO THE
          PLATFORM AND YOUR USE THEREOF, INCLUDING, BUT NOT LIMITED TO,
          WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD
          PARTIES' RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE AND ANY WARRANTIES
          ARISING FROM A COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF
          TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE DO NOT
          REPRESENT OR WARRANT THAT: (I) YOUR ACCESS TO OR USE OF THE PLATFORM
          WILL MEET YOUR REQUIREMENTS; (II) YOUR ACCESS TO OR USE OF THE
          PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR;
          (III) DATA PROVIDED THROUGH THE PLATFORM WILL BE ACCURATE; (IV) THE
          PLATFORM OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR
          THROUGH THE PLATFORM ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS;
          OR (V) ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE PLATFORM WILL BE
          SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
          WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE
          EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>
        <p className='legalPageSectionContent'>
          THE PLATFORM MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL
          ERRORS OR OMISSIONS. UNLESS REQUIRED BY APPLICABLE LAWS, WE ARE NOT
          RESPONSIBLE FOR ANY SUCH TYPOGRAPHICAL OR TECHNICAL ERRORS LISTED ON
          THE PLATFORM. WE RESERVE THE RIGHT TO MAKE CHANGES, CORRECTIONS,
          AND/OR IMPROVEMENTS TO THE PLATFORM AT ANY TIME WITHOUT NOTICE.
        </p>
        <p className='legalPageSectionContent'>
          BAXUS PROVIDES A TECHNOLOGY PLATFORM THAT CONNECTS SELLERS AND
          COLLECTORS AND FACILITATES TRANSACTIONS BETWEEN SELLERS AND COLLECTORS
          OF COLLECTIBLE NFT ASSETS. BAXUS IS NOT A BROKER, FINANCIAL
          INSTITUTION, OR CREDITOR. WE DO NOT AND CANNOT CONTROL THE ACTIONS OR
          OMISSIONS OF ANY SELLER OR COLLECTOR. YOUR INTERACTIONS AND
          TRANSACTIONS WITH ANY SELLER OR COLLECTOR, AS APPLICABLE, THROUGH THE
          PLATFORM ARE SOLELY BETWEEN YOU AND THAT SELLER OR COLLECTOR, AND WE
          ARE NOT A PARTY TO ANY SUCH INTERACTION OR TRANSACTION. WE HEREBY
          DISCLAIMS ANY AND ALL LIABILITY FOR ANY ACT OR OMISSION OF ANY USER OF
          THE PLATFORM, AND ALL LIABILITY IN CONNECTION WITH ALL INTERACTIONS OR
          TRANSACTIONS MADE BETWEEN ANY SELLER AND COLLECTOR OR AMONG ANY OTHER
          USERS OF THE PLATFORM. IF YOU HAVE AN ISSUE WITH ANY INTERACTION OR
          TRANSACTION THAT YOU ENTERED INTO WITH ANOTHER USER OF THE PLATFORM,
          YOU MUST ADDRESS THAT ISSUE DIRECTLY WITH THE OTHER USER AND NOT WITH
          US - WE HAVE NO OBLIGATION TO ASSIST IN ANY OF THESE DISPUTES.
        </p>
        <p className='legalPageSectionContent'>
          IN CONNECTION WITH ANY WARRANTY, CONTRACT, OR COMMON LAW TORT CLAIMS,
          WE SHALL NOT BE LIABLE FOR (I) INCIDENTAL OR CONSEQUENTIAL DAMAGES,
          LOST PROFITS, OR DAMAGES RESULTING FROM THE ACCESS AND USE OF THE
          PLATFORM, OR THE INABILITY TO ACCESS AND USE THE PLATFORM, OR (II)
          DIRECT DAMAGES IN EXCESS OF AN AMOUNT EQUAL TO THE TRANSACTION FEES
          THAT WE HAVE COLLECTED FROM YOU IN THE THREE (3) MONTHS IMMEDIATELY
          PRECEDING THE CLAIM, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES.
        </p>
        <h4 className='legalPageSectionHeader'>
          ASSUMPTION OF THE RISK RELATING TO NFTS AND BLOCKCHAIN
        </h4>
        <h4 className='legalPageSectionHeader'>Volatility</h4>
        <p className='legalPageSectionContent'>
          The prices of NFTs are extremely volatile and subjective, as may be
          the value of the physical assets they represent. NFTs have no inherent
          or intrinsic value. Fluctuations in the price of other digital assets
          could materially and adversely affect the value of your Collectible
          NFT Asset, which may also be subject to significant price volatility.
          We cannot guarantee that any Collectible NFT Assets purchased, or
          their corresponding physical assets, will retain their original value,
          as the value of collectibles is inherently subjective and factors
          occurring outside of the Platform may materially impact the value and
          desirability of any particular Collectible NFT Asset or corresponding
          physical asset.
        </p>
        <h4 className='legalPageSectionHeader'>Inherent Risks</h4>
        <p className='legalPageSectionContent'>
          There are risks associated with using NFTs, including, but not limited
          to, the risk of hardware, software and Internet connections, the risk
          of malicious software introduction, and the risk that third parties
          may obtain unauthorized access to information stored within your
          Wallet. The regulatory regime governing blockchain technologies,
          cryptocurrencies and tokens is uncertain, and new regulations or
          policies may materially adversely affect the potential utility or
          value of your Collectible NFT Assets. ANY COLLECTIBLE NFT ASSET YOU
          PURCHASE OR SALE, TRANSFER OF A COLLECTIBLE NFT ASSET YOU MAKE, ACCEPT
          OR FACILITATE, WHETHER INSIDE OR OUTSIDE OF THIS PLATFORM, WILL BE
          ENTIRELY AT YOUR RISK. WE DO NOT CONTROL OR ENDORSE PURCHASES OR SALES
          OF COLLECTIBLE NFT ASSETS OUTSIDE OF THIS PLATFORM.
        </p>
        <h4 className='legalPageSectionHeader'>Use of Blockchain</h4>
        <p className='legalPageSectionContent'>
          NFTs exist only by virtue of the ownership record maintained on the
          Platform's supporting blockchain in the Solana Network. We may store
          the Collectible NFT Assets on our own servers or those of our third
          party service providers. Any transfer of Collectible NFT Assets occur
          within the supporting blockchain in the Solana Network, and not on the
          Platform. YOU ACCEPT AND ACKNOWLEDGE THAT WE WILL NOT BE RESPONSIBLE
          FOR ANY COMMUNICATION FAILURES, DISRUPTIONS, ERRORS, DISTORTIONS OR
          DELAYS YOU MAY EXPERIENCE WHEN USING THE SOLANA NETWORK, HOWEVER
          CAUSED. UPGRADES TO THE SOLANA NETWORK, OR A CHANGE IN HOW
          TRANSACTIONS ARE CONFIRMED ON THE SOLANA NETWORK MAY HAVE UNINTENDED,
          ADVERSE EFFECTS ON ALL BLOCKCHAINS USING THE SOLANA NETWORK.
        </p>
        <p className='legalPageSectionContent'>
          NFTs are intangible digital assets that exist only by virtue of the
          ownership record maintained in the Solana Network. All smart contracts
          are conducted and occur on the decentralized ledger within the Solana
          Network. WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES
          WITH RESPECT TO SMART CONTRACTS. WE ARE NOT RESPONSIBLE FOR LOSSES DUE
          TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE SOLANA NETWORK, OR ANY
          ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY
          DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH
          THE BLOCKCHAIN SUPPORTING THE SOLANA NETWORK, INCLUDING FORKS,
          TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A
          RESULT.
        </p>
        <p className='legalPageSectionContent'>
          WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR
          AS THE RESULT OF YOUR USE OF THE SOLANA NETWORK, OR YOUR ELECTRONIC
          WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS
          ARISING FROM: (I) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR
          INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (II)
          SERVER FAILURE OR DATA LOSS; (III) CORRUPTED WALLET FILES; OR (IV)
          UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING, BUT NOT
          LIMITED TO, THE USE OF VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS
          OF ATTACK AGAINST THE PLATFORM, THE SOLANA NETWORK, OR ANY ELECTRONIC
          WALLET.
        </p>
        <h4 className='legalPageSectionHeader'>EXTERNAL SITES</h4>
        <p className='legalPageSectionContent'>
          The Site and the Platform may contain links to third-party websites
          (“External Sites”). These links are provided solely as a convenience
          to you and not as an endorsement by us of the content or products
          available through such External Sites. The External Sites may offer
          third-party goods and services (the “Third Party Products”), and you
          acknowledge and agree that: (i) we do not manufacture or sell the
          Third Party Products and thus have no control over the quality,
          safety, legality or efficacy of any Third Party Product; (ii) we have
          no involvement in any transaction involving any Third Party Products;
          and (iii) we shall have no liability with respect to any Third Party
          Product or any transaction or interaction between you and the provider
          of any Third Party Product.
        </p>
        <p className='legalPageSectionContent'>
          The content of such External Sites is developed and provided by
          others. You should contact the site administrator or webmaster for
          those External Sites if you have any concerns regarding such links or
          any content located on such External Sites. We are not responsible for
          the content of any linked External Sites and do not make any
          representations regarding the content or accuracy of materials on such
          External Sites. You should take precautions when downloading files
          from all websites to protect your computer from viruses and other
          destructive programs. If you decide to access linked External Sites,
          you do so at your own risk.
        </p>
        <h4 className='legalPageSectionHeader'>INDEMNIFICATION</h4>
        <p className='legalPageSectionContent'>
          You agree to defend, indemnify, and hold us and our officers,
          directors, employees, successors, licensees, and assigns harmless from
          and against any claims, actions, or demands, including, without
          limitation, reasonable legal and accounting fees, arising or resulting
          from (i) breach of any of your representations, warranties or other
          obligations under these Terms of Use; (ii) your misuse of the Site,
          the Platform and/or the Baxus Content; (iii) your gross negligence or
          willful misconduct; (iv) any transaction that you enter into with
          another user of the Platform; or (v) any actual or alleged
          infringement or misappropriation of third party intellectual property
          rights by any content provided by you through the Platform. We shall
          provide notice to you of any such claim, suit, or proceeding and shall
          assist you, at your expense, in defending any such claim, suit, or
          proceeding. We reserve the right, at your expense, to assume the
          exclusive defense and control of any matter that is subject to
          indemnification under this section. In such case, you agree to
          cooperate with any reasonable requests assisting our defense of such
          matter.
        </p>
        <h4 className='legalPageSectionHeader'>
          COMPLIANCE WITH APPLICABLE LAWS
        </h4>
        <p className='legalPageSectionContent'>
          The Site and the Platform is based in the United States. We make no
          claims concerning whether any content may be downloaded, viewed, or be
          appropriate for use outside of the United States. If you access or use
          the Site and the Platform from outside of the United States, you do so
          at your own risk. Whether inside or outside of the United States, you
          are solely responsible for ensuring compliance with the laws of your
          specific jurisdiction.
        </p>
        <h4 className='legalPageSectionHeader'>TERMINATION</h4>
        <p className='legalPageSectionContent'>
          We reserve the right, in our sole discretion, to terminate these Terms
          or Use and/or restrict, suspend, or terminate your access to all or
          any part of the Site and the Platform, at any time and for any reason
          without prior notice or liability. We reserve the right to change,
          suspend, or discontinue all or any part of the Site and the Platform
          at any time without prior notice or liability.
        </p>
        <h4 className='legalPageSectionHeader'>
          DIGITAL MILLENNIUM COPYRIGHT ACT
        </h4>
        <h4 className='legalPageSectionHeader'>
          Reporting Claims of Copyright Infringement
        </h4>
        <p className='legalPageSectionContent'>
          We respect the intellectual property rights of others and attempt to
          comply with all relevant laws. We will review all claims of copyright
          infringement received and remove any content deemed to have been
          posted or distributed in violation of any such laws. Our designated
          agent under the Digital Millennium Copyright Act (the “Act”) for the
          receipt of any Notification of Claimed Infringement which may be given
          under that Act is as follows:
        </p>
        Copyright Agent _________________ __________________ [Email address]
        <p className='legalPageSectionContent'>
          If you believe that your work has been copied on the Platform in a way
          that constitutes copyright infringement, please provide our agent with
          notice in accordance with the requirements of the Act, including (i) a
          description of the copyrighted work that has been infringed and the
          specific location on the Platform where such work is located; (ii) a
          description of the location of the original or an authorized copy of
          the copyrighted work; (iii) your address, telephone number and e-mail
          address; (iv) a statement by you that you have a good faith belief
          that the disputed use is not authorized by the copyright owner, its
          agent or the law; (v) a statement by you, made under penalty of
          perjury, that the information in your notice is accurate and that you
          are the copyright owner or authorized to act on the copyright owner's
          behalf; and (vi) an electronic or physical signature of the owner of
          the copyright or the person authorized to act on behalf of the owner
          of the copyright interest.
        </p>
        <h4 className='legalPageSectionHeader'>
          Counter-Notification Procedures
        </h4>
        <p className='legalPageSectionContent'>
          If you believe that material you posted on the Platform was removed or
          access to it was disabled by mistake or misidentification, you may
          file a counter-notification with us (a “Counter-Notice”) by submitting
          written notification to our copyright agent (identified above).
        </p>
        <p className='legalPageSectionContent'>
          Pursuant to the Act, the Counter-Notice must include substantially the
          following: (i) your physical or electronic signature; (ii) an
          identification of the material that has been removed or to which
          access has been disabled and the location at which the material
          appeared before it was removed or access disabled; (iii) adequate
          information by which we can contact you (including your name, postal
          address, telephone number, and, if available, email address); (iv) a
          statement under penalty of perjury by you that you have a good faith
          belief that the material identified above was removed or disabled as a
          result of a mistake or misidentification of the material to be removed
          or disabled; (v) a statement that you will consent to the jurisdiction
          of the Federal District Court for the judicial district in which your
          address is located (or if you reside outside the United States for any
          judicial district in which the Site may be found) and that you will
          accept service from the person (or an agent of that person) who
          provided the Site with the complaint at issue.
        </p>
        <p className='legalPageSectionContent'>
          The Act allows us to restore the removed content if the party filing
          the original notice does not file a court action against you within
          ten business days of receiving the copy of your Counter-Notice.
        </p>
        <p className='legalPageSectionContent'>
          Please be aware that if you knowingly materially misrepresent that
          material or activity on the Site was removed or disabled by mistake or
          misidentification, you may be held liable for damages (including costs
          and attorneys' fees) under Section 512(f) of the Act.
        </p>
        <h4 className='legalPageSectionHeader'>BINDING ARBITRATION</h4>
        <p className='legalPageSectionContent'>
          Subject to Section 18 below, in the event of a dispute arising under
          or relating to these Terms of Use (“Dispute”), such Dispute will be
          finally and exclusively resolved by binding arbitration governed by
          the Federal Arbitration Act (“FAA”). NEITHER PARTY SHALL HAVE THE
          RIGHT TO LITIGATE SUCH CLAIM IN COURT OR TO HAVE A JURY TRIAL, EXCEPT
          EITHER PARTY MAY BRING ITS CLAIM IN ITS LOCAL SMALL CLAIMS COURT, IF
          PERMITTED BY THAT SMALL CLAIMS COURT RULES AND IF WITHIN SUCH COURT'S
          JURISDICTION. ARBITRATION IS DIFFERENT FROM COURT, AND DISCOVERY AND
          APPEAL RIGHTS MAY ALSO BE LIMITED IN ARBITRATION. All disputes will be
          resolved before a neutral arbitrator selected jointly by the parties,
          whose decision will be final, except for a limited right of appeal
          under the FAA. The arbitration shall be commenced and conducted by
          JAMS pursuant to its then current Comprehensive Arbitration Rules and
          Procedures and in accordance with the Expedited Procedures in those
          rules, or, where appropriate, pursuant to JAMS' Streamlined
          Arbitration Rules and Procedures. All applicable JAMS' rules and
          procedures are available at the JAMS website www.jamsadr.com. Each
          party will be responsible for paying any JAMS filing, administrative
          and arbitrator fees in accordance with JAMS rules. Judgment on the
          arbitrator's award may be entered in any court having jurisdiction.
          This clause shall not preclude parties from seeking provisional
          remedies in aid of arbitration from a court of appropriate
          jurisdiction. The arbitration may be conducted in person, through the
          submission of documents, by phone, or online. If conducted in person,
          the arbitration shall take place in the United States county where you
          reside. The parties may litigate in court to compel arbitration, to
          stay a proceeding pending arbitration, or to confirm, modify, vacate
          or enter judgment on the award entered by the arbitrator. The parties
          shall cooperate in good faith in the voluntary and informal exchange
          of all non-privileged documents and other information (including
          electronically stored information) relevant to the Dispute immediately
          after commencement of the arbitration. Nothing in these Terms of Use
          will prevent us from seeking injunctive relief in any court of
          competent jurisdiction as necessary to protect our proprietary
          interests.
        </p>
        <h4 className='legalPageSectionHeader'>CLASS ACTION WAIVER</h4>
        <p className='legalPageSectionContent'>
          You agree that any arbitration or proceeding shall be limited to the
          Dispute between us and you individually. To the full extent permitted
          by law, (i) no arbitration or proceeding shall be joined with any
          other; (ii) there is no right or authority for any Dispute to be
          arbitrated or resolved on a class action-basis or to utilize class
          action procedures; and (iii) there is no right or authority for any
          Dispute to be brought in a purported representative capacity on behalf
          of the general public or any other persons. YOU AGREE THAT YOU MAY
          BRING CLAIMS AGAINST US ONLY IN YOUR INDIVIDUAL CAPACITY AND NOT AS A
          PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
          PROCEEDING.
        </p>
        <h4 className='legalPageSectionHeader'>EQUITABLE RELIEF</h4>
        <p className='legalPageSectionContent'>
          You acknowledge and agree that in the event of a breach or threatened
          violation of our intellectual property rights or confidential and
          proprietary information by you, we will suffer irreparable harm and
          will therefore be entitled to injunctive relief to enforce these Terms
          of Use. We may, without waiving any other remedies under these Terms
          of Use, seek from any court having jurisdiction any interim,
          equitable, provisional, or injunctive relief that is necessary to
          protect our rights and property pending the outcome of the arbitration
          referenced above. You hereby irrevocably and unconditionally consent
          to the personal and subject matter jurisdiction of the federal and
          state courts in the State of New Jersey for purposes of any such
          action by us.
        </p>
        <h4 className='legalPageSectionHeader'>MISCELLANEOUS</h4>
        <p className='legalPageSectionContent'>
          These Terms of Use and any action related thereto will be governed by
          the laws of the State of New Jersey without regard to its conflict of
          laws provisions. Proceedings commenced by us to protect our
          intellectual property or confidential information shall be brought in
          the state or federal courts located in the State of New Jersey.
        </p>
        <p className='legalPageSectionContent'>
          If the Agreement is terminated in accordance with the termination
          provision in Section 14 bove, such termination shall not affect the
          validity of the following provisions of this Agreement, which shall
          remain in full force and effect: “Taxes,” “Intellectual Property,”
          “Communications with Us,” “Disclaimers; Limitations of Liability,”
          “Assumption of the Risk relating to NFTs and Blockchain,”
          “Indemnification,” “Compliance with Applicable Laws,” “Termination of
          the Agreement,” and “Miscellaneous.”
        </p>
        <p className='legalPageSectionContent'>
          Our failure to act on or enforce any provision of these Terms of Use
          shall not be construed as a waiver of that provision or any other
          provision in these Terms of Use. No waiver shall be effective against
          us unless made in writing, and no such waiver shall be construed as a
          waiver in any other or subsequent instance. Except as expressly agreed
          by us and you in writing, these Terms of Use, together with our
          Privacy Policy constitutes the entire agreement between you and us,
          and supersedes all previous or contemporaneous agreements, whether
          written or oral, between you and us, with respect to the subject
          matter hereof. The section headings are provided merely for
          convenience and shall not be given any legal import. These Terms of
          Use will inure to the benefit of our successors, assigns, licensees,
          and sublicensees. These Terms of Use may be updated by us from time to
          time, with reasonable notice to you, including via transmission of
          such updated terms via electronic means.
        </p>
        <p>Copyright 2022 BAXUS CORP. All rights reserved.</p>
      </div>
    </div>
  )
}

export default TermsConditions
