import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import parse, { attributesToProps } from "html-react-parser";

import faqQuestions from "../Content/FAQ/faqQuestions";
import MailtoLink from "@baxus/shared/dist/Components/MailtoLink";
import { ChevronRight, ChevronLeft } from "react-bootstrap-icons";
import { USDFormatter } from "@baxus/shared/dist/Utils/Utils";

import DOMPurify from "dompurify";
import "./carousel.css";
import axios from "axios";

export default function CarouselSection({ assets }) {
  // const [selectedQuestion, setSelectedQuestion] = useState(dummyData[0]);
  const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(0);

  const handleSelect = (selectedIndex) => {
    let curSelected = selectedIndex;
    if (selectedIndex < 0) {
      curSelected = assets.length - 1;
    } else if (selectedIndex >= assets.length) {
      curSelected = 0;
    }
    setSelectedQuestionIdx(curSelected);
    // setSelectedQuestion(assets[curSelected]);
  };
  const renderCarouselItems = () => {
    const parseOptions = {
      replace: (domNode) => {
        if (domNode.name === "a") {
          const props = attributesToProps(domNode.attribs);
          const { href, "data-subject": subject } = props;
          return (
            <MailtoLink
              url={`mailto:${href}?subject=${subject}`}
              label={domNode.children[0].data}
              {...props}
            />
          );
        }
      },
    };
    return assets.map((data, i) => {
      // const cleanAnswer = DOMPurify.sanitize(question.answer); //sanitize html to prevent xss
      return (
        <Carousel.Item key={data.id}>
          <div className="row mobileScreen">
            <div
              className="col-lg-5 col-md-6 col-sm-6 col-xs-12 "
              style={{ marginTop: "1rem" }}
            >
              <div className="imgSection">
                <img src={data.cover} alt="" />
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-6 col-xs-12 featuredAssetImgWrapper1 "
              style={{ marginTop: "1rem" }}
            >
              <h6>{data.producer}</h6>
              <h4>{data?.name}</h4>
              <p>{data?.desc_long}</p>

              {data.price && (
                <div className="featuredAssetHighlightCTASection">
                  {data.price ? (
                    <h4 className="fontSection">
                      {USDFormatter.format(data.price)}
                    </h4>
                  ) : (
                    <></>
                  )}
                  {data.Sol ? (
                    <>
                      <img src={data.imageSolana} alt="" />
                      <h4 className="fontSection">{data.Sol}</h4>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <button className="btnSection1">
                <a href={data.Files} target="_blank" rel="noreferrer">
                  Buy Now
                </a>
              </button>
            </div>
          </div>
        </Carousel.Item>
      );
    });
  };

  return (
    <>
      <div className="faqWrapper row mobileScreen" id="faq-sec">
        <div className="faqQuestionAreaWrapper">
          {/* <h2 className="faqQuestionHeader">{selectedQuestion.question}</h2> */}
        </div>

        <div className="faqCarouselWrapper1 col-lg-12 col-md-12 col-sm-12">
          <div />
          <div className="faqBGWrapper" />
          <div className="faqControlsWrapper1">
            <button
              className="ControlButton"
              onClick={() => {
                handleSelect(selectedQuestionIdx - 1);
              }}
            >
              <ChevronLeft className="icon" />
            </button>
            <button
              className="ControlButton"
              onClick={() => {
                handleSelect(selectedQuestionIdx + 1);
              }}
            >
              <ChevronRight className="icon" />
            </button>
          </div>

          <Carousel
            activeIndex={selectedQuestionIdx}
            onSelect={handleSelect}
            controls={false}
            interval={null}
          >
            {renderCarouselItems()}
          </Carousel>
        </div>
      </div>
    </>
  );
}
