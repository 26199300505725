import React, { useState } from "react";
import "./SearchBar.css";
import { useNavigate } from "react-router-dom";

export default function Searchbar({ data }) {
  const history = useNavigate();

  return (
    <>
      <h6
        className="searchBoxContent"
        onClick={() => {
          document.getElementById("serch").style.display = "none";
          history("/assets", { state: data });
        }}
      >
        {data.name}
      </h6>
    </>
  );
}
