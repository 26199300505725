
const tradeContent = {
  header: "Coming Soon: Trade",
  subHeader: "Our services",
  description: "Trade your proof of ownership across the Solana blockchain using trustless contracts. Get intra-day pricing information for your assets and discover a new market of buyers and sellers.",
  isForm: true,
  cta: "Pre-register Now",
  ctaLink: process.env.REACT_APP_MAILCHIMP_TRADE_REGISTER_URL,
  imgSrc: "https://d241vrp4b4n7qa.cloudfront.net/Assets/trade_hero.png",
}

export default tradeContent