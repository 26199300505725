import { v4 as uuidv4 } from "uuid"

const faqQuestions = [
  {
    question: "What is BAXUS?",
    answer:
      "BAXUS is the infrastructure underlying the tokenization of physical assets. We allow collectors and investors to safely store and secure their assets while maintaining proof of ownership and authentication through NFTs.",
    id: uuidv4(),
  },
  {
    question: "Why should I tokenize on BAXUS?",
    answer:
      "BAXUS provides peace of mind that your valuables are safe and secure, every owner who lists an item on BAXUS earns royalties on each future transaction of the item. BAXUS provides each asset with historical trade pricing, giving sellers and buyers a better understanding of an item's real market value.",
    id: uuidv4(),
  },
  {
    question: "How do I redeem the actual items?",
    answer:
      "BAXUS assets can be redeemed at any time using the redeem function on our website. When you redeem an asset the NFT is transferred into an escrow wallet where it remains until the physical item is delivered. After the physical item is delivered the NFT is burned, so that the physical and digital asset cannot exist in circulation at the same time. For any questions about redemptions email redemptions@baxus.co. When an individual redeems their asset they will be asked to enter their shipping address. BAXUS will work with customers to ensure legal compliance with local laws for redemptions pertaining to wines and spirits. Costs associated with shipping and duties will be charged to the individuals who choose to redeem their assets.",
    id: uuidv4(),
  },
  {
    question: "What fees does BAXUS charge?",
    answer:
      "BAXUS' platform is designed to provide collectors and investors a seamless transaction experience. Owners pay no fees for storage or insurance. Owners pay a $15 fee per asset that they send to BAXUS which covers authentication, scanning, and NFT minting. Because we are built on Solana, users will not have to worry about high gas fees when transacting on the blockchain. BAXUS charges sellers a 10% fee, with no buyer's premium. This gives BAXUS sellers a significant advantage over traditional auction houses which charge a sellers fee as well as a buyer's premium, leaving less money for the sellers. The fees BAXUS collects are used to pay royalties to the original owner of the asset, cover warehousing, insurance, and operational expenses. Shipping fees and duties will only be charged upon redemption.",
    id: uuidv4(),
  },
  {
    question: "How are the bottles authenticated?",
    answer:
      "Bottles are authenticated using a combination of expert analysis and advanced computer-vision technology. Each asset is inspected to ensure its integrity and its contents before undergoing a computer-vision analysis during the scanning process. Each type of asset listed on BAXUS undergoes extensive verification and authentication by experts with decades of experience in their respective fields. Assets that are unable to be authenticated will not be listed on BAXUS.",
    id: uuidv4(),
  },
  {
    question: "How do I list my bottles on BAXUS?",
    answer:
      "BAXUS is currently accepting applications to become a BAXUS seller. If you are interested in having your assets stored and listed on BAXUS, contact info@baxus.co",
    id: uuidv4(),
  },
]

export default faqQuestions
