import React from "react";
import "../../Styles/Homepage.styles.scss";
import { ChevronRight } from "react-bootstrap-icons";

const SplashSection = () => {
  const scrollToFirstSection = () => {
    const firstSec = document.querySelector(".page-first-section");
    window.scroll({
      top: firstSec.offsetTop - 80,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="splashWrapper">
      <video className="splashVideo" autoPlay loop muted>
        <source
          src="https://d241vrp4b4n7qa.cloudfront.net/Assets/Web_whiskey_clip.mp4"
          type="video/mp4"
        />
      </video>

      <div className="splashContent">
        <div className="splashLogo">
          <img src="https://d241vrp4b4n7qa.cloudfront.net/Assets/logo_horiz.png" />
        </div>

        <div className="splashText">
          <h1 className="splashHeader">
            The new way to buy, trade, and secure tangible assets on the
            blockchain.
          </h1>
          <button onClick={() => scrollToFirstSection()} className="splashCTA">
            Let's get started
            <ChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SplashSection;
