import React from "react"
import HPSectionCTA from "./HPSectionCTA"
import "../../Styles/Homepage.styles.scss"

const HPImgLeft = ({ content }) => {
  const { header, subHeader, description, isForm, cta, ctaLink, imgSrc } =
    content
  return (
    <div className='leftImgSecWrapper container'>
      <div className='leftImgSecImgWrapper'>
        <img src={imgSrc} alt={header} />
      </div>
      <div className='leftSideBorderWrapper' />
      <div className='leftImgSecContent'>
        <div className='servicesContent'>
          <p className='servicesSubheader'>{subHeader}</p>
          <h2>{header}</h2>
          <p className='servicesDescription'>{description}</p>
          {cta !== "" && (
            <HPSectionCTA isForm={isForm} cta={cta} ctaLink={ctaLink} />
          )}
        </div>
      </div>
    </div>
  )
}

export default HPImgLeft
