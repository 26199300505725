import React, { forwardRef } from "react"
import "../../Styles/FeaturedAssets.styles.scss"

const FeaturedAsset = forwardRef(
  ({ assetDetails, curIndex, selectAsset, assetSelected }, ref) => {
    let isNotSelectedAsset = assetSelected && curIndex !== 0
    const { Name, S3: imgUrl } = assetDetails

    return (
      <div
        className={`featuredAssetWrapper ${
          isNotSelectedAsset
            ? `unselectedFeaturedAsset unselectedFeaturedAsset-${curIndex}`
            : ""
        }`}
        ref={ref}
        onClick={() => selectAsset(curIndex)}
      >
        <div className='featuredAssetContentWrapper'>
          <div className='featuredAssetImgWrapper'>
            <div className='featuredAssetImgBg' />
            <img src={imgUrl} alt={Name} />
          </div>
        </div>
      </div>
    )
  }
)

export default FeaturedAsset
