"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USDFormatter = void 0;
var USDFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});
exports.USDFormatter = USDFormatter;