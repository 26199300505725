import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { ChevronRight, X } from "react-bootstrap-icons";
import "../../Styles/FeaturedAssets.styles.scss";

const FeaturedMobileAssetCarousel = ({ assets, USDFormatter }) => {
  const [isAssetSelected, setIsAssetSelected] = useState(false);
  const [curAssetSelectedIdx, setCurAssetSelectedIdx] = useState(0);

  useEffect(() => {
    if (isAssetSelected) {
      const titleElement = document.getElementById("featuredMobile");
      titleElement.scrollIntoView({ block: "nearest" });
    }
  }, [isAssetSelected]);

  const handleSelect = (selectedIndex) => {
    let curSelected = selectedIndex;
    if (selectedIndex < 0) {
      curSelected = assets.length - 1;
    } else if (selectedIndex >= assets.length) {
      curSelected = 0;
    }
    setCurAssetSelectedIdx(curSelected);
  };

  const toggleAssetSelected = (i) => {
    setIsAssetSelected(!isAssetSelected);
    if (isAssetSelected) {
      setCurAssetSelectedIdx(i);
    }
  };

  const closeAssetSelected = () => {
    setIsAssetSelected(false);
    const featuredElement = document.querySelector(".page-first-section");
    featuredElement.scrollIntoView();
  };

  const renderMobileAssets = () => {
    return assets.map((asset, i) => {
      const { name, axu_id, cover } = asset;
      return (
        <Carousel.Item key={axu_id}>
          <div
            className="mobileFeaturedAssetWrapper"
            onClick={() => toggleAssetSelected(i)}
          >
            <div className="featuredAssetContentWrapper">
              <div className="featuredAssetImgWrapper">
                <div className="featuredAssetImgBg" />
                <img src={cover} alt={name} />
              </div>
            </div>
          </div>
        </Carousel.Item>
      );
    });
  };

  const renderSelectedAssetDetails = () => {
    const {
      name,
      desc_long,
      OgCaskYield,
      time_created,
      price,
      MSRP,

      AnnualizedRet,
      Files: MetaplexLink,
    } = assets[curAssetSelectedIdx];
    return (
      <div className="mobileAssetSelctedWrapper" id="featuredMobile">
        <X
          className="featuredAssetSelectedEsc"
          onClick={() => closeAssetSelected()}
        />
        <div className="featuredAssetSelctedContent">
          <h2 className="featuredAssetSelctedHeader">{name}</h2>
          <p>{desc_long}</p>
          <div className="featuredAssetSelectedBottleDetails">
            <div className="featuredAssetSelectedBottleDetail">
              Amount Bottled:{" "}
              <span className="featuredAssetSelectedBottleInfo">
                {OgCaskYield || "n/a"}
              </span>
            </div>
            <div className="featuredAssetSelectedBottleDetail">
              Year released:{" "}
              <span className="featuredAssetSelectedBottleInfo">
                {time_created || "n/a"}
              </span>
            </div>
            <div className="featuredAssetSelectedBottleDetail">
              Original MSRP:{" "}
              <span className="featuredAssetSelectedBottleInfo">
                {MSRP ? USDFormatter.format(MSRP) : "n/a"}
              </span>
            </div>
            <div className="featuredAssetSelectedBottleDetail">
              Current market value:{" "}
              <span className="featuredAssetSelectedBottleInfo">
                {price ? USDFormatter.format(price) : "n/a"}
              </span>
            </div>
            <div className="featuredAssetSelectedBottleDetail">
              Annualized return:{" "}
              <span className="featuredAssetSelectedBottleInfo">
                {`${AnnualizedRet}%` || "n/a"}
              </span>
            </div>
          </div>
          {MetaplexLink ? (
            <div className="featuredAssetHighlightCTASection">
              <button className="featuredAssetHighlightCTAButton">
                <a href={MetaplexLink} target="_blank" rel="noreferrer">
                  Buy Now
                </a>
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Carousel
        activeIndex={curAssetSelectedIdx}
        onSelect={handleSelect}
        controls={false}
        interval={null}
      >
        {renderMobileAssets()}
      </Carousel>
      {isAssetSelected ? renderSelectedAssetDetails() : <></>}
    </div>
  );
};

export default FeaturedMobileAssetCarousel;

/*
<div className="featuredAssetHighlightCTASection">
            <button className="featuredAssetHighlightCTAButton"><a href="#">Buy Now</a></button>
            <button className="featuredAssetHighlightCTAButton"><a href="/Assets">Explore the collection<ChevronRight /></a></button>
          </div>
*/
