import React, { useState } from "react"
import parse, { attributesToProps } from "html-react-parser"
import DOMPurify from "dompurify"
import MailtoLink from "@baxus/shared/dist/Components/MailtoLink"
import Carousel from "react-bootstrap/Carousel"
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons"
import faqQuestions from "../../Content/FAQ/faqQuestions"
import "../../Styles/Homepage.styles.scss"

const HPFAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(faqQuestions[0])
  const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(0)

  const handleSelect = (selectedIndex) => {
    let curSelected = selectedIndex
    if (selectedIndex < 0) {
      curSelected = faqQuestions.length - 1
    } else if (selectedIndex >= faqQuestions.length) {
      curSelected = 0
    }
    setSelectedQuestionIdx(curSelected)
    setSelectedQuestion(faqQuestions[curSelected])
  }

  const renderCarouselItems = () => {
    const parseOptions = {
      replace: (domNode) => {
        if (domNode.name === "a") {
          const props = attributesToProps(domNode.attribs)
          const { href, "data-subject": subject } = props
          return (
            <MailtoLink
              url={`mailto:${href}?subject=${subject}`}
              label={domNode.children[0].data}
              {...props}
            />
          )
        }
      },
    }

    return faqQuestions.map((question) => {
      const cleanAnswer = DOMPurify.sanitize(question.answer) //sanitize html to prevent xss
      return (
        <Carousel.Item key={question.id}>
          {parse(`<p>${cleanAnswer}</p>`, parseOptions)}
        </Carousel.Item>
      )
    })
  }

  return (
    <div className='faqWrapper container' id='faq-sec'>
      <div className='faqQuestionAreaWrapper'>
        <p className='faqQuestionSubHeader'>Our FAQ</p>
        <h2 className='faqQuestionHeader'>{selectedQuestion.question}</h2>
        <div className='faqControlsWrapper'>
          <button
            className='faqControlButton'
            onClick={() => {
              handleSelect(selectedQuestionIdx - 1)
            }}
          >
            <ArrowLeftCircle />
          </button>
          <button
            className='faqControlButton'
            onClick={() => {
              handleSelect(selectedQuestionIdx + 1)
            }}
          >
            <ArrowRightCircle />
          </button>
        </div>
      </div>
      <div className='faqCarouselWrapper'>
        <div className='faqBGBorder' />
        <div className='faqBGWrapper' />
        <Carousel
          activeIndex={selectedQuestionIdx}
          onSelect={handleSelect}
          controls={false}
          interval={null}
        >
          {renderCarouselItems()}
        </Carousel>
      </div>
    </div>
  )
}

export default HPFAQ
