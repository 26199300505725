import React, { useState, useEffect } from "react";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { useCookies } from "react-cookie";
import Loader from "@baxus/shared/dist/Components/Loader";
import SplashSection from "../Components/Homepage/SplashSection";
import HPAssetsWrapper from "../Components/Homepage/HPAssetsWrapper";
import HPFAQ from "../Components/Homepage/HPFAQ";
import HPMobileFAQ from "../Components/Homepage/HPMobileFAQ";
import HPImgLeft from "../Components/Homepage/HPImgLeft";
import HPImgRight from "../Components/Homepage/HPImgRight";
import AgeValidation from "../Components/Homepage/AgeValidation";
import "../Styles/Homepage.styles.scss";

// Content + Assets
import verificationContent from "../Content/Homepage/verification";
import insureContent from "../Content/Homepage/insure";
import tokenizationContent from "../Content/Homepage/tokenization";
import tradeContent from "../Content/Homepage/trade";

import Collection from "../Components/Homepage/Collection";

const Homepage = () => {
  const [loading, setLoading] = useState(true);
  const [isDisplayAgeValidation, setIsDisplayAgeValidation] = useState();
  const [cookies, setCookie] = useCookies();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    const existingFirstVisitCookie = cookies["INFV"];
    const existingIsAgeValidCookie = cookies["IAV"];
    if (existingFirstVisitCookie && existingIsAgeValidCookie) {
      setIsDisplayAgeValidation(false);
    } else {
      setIsDisplayAgeValidation(true);
    }
    setCookie("INFV", true, { expires: moment().add(2, "y").toDate() }); //set first visit cookie
    setLoading(false);
  }, []);

  const setAgeConfirmation = () => {
    setCookie("IAV", true, { expires: moment().add(2, "y").toDate() }); //set age valid cookie
    setIsDisplayAgeValidation(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="homepageWrapper">
          <SplashSection />
          {/* <div className="hp-sec assets-sec page-first-section">
            <HPAssetsWrapper />
          </div> */}

          <div className="hp-sec verification-sec  page-first-section">
            <HPImgLeft content={verificationContent} />
          </div>
          <div className="hp-sec insure-sec">
            <HPImgRight content={insureContent} />
          </div>
          <div className="hp-sec tokenization-sec">
            <HPImgLeft content={tokenizationContent} />
          </div>
          <div className="hp-sec trade-sec">
            <HPImgRight content={tradeContent} />
          </div>

          <div className="hp-sec faq-sec">
            {isMobile ? <HPMobileFAQ /> : <HPFAQ />}
          </div>
          <div>
            <Collection />
          </div>
          <AgeValidation
            setAgeConfirmation={setAgeConfirmation}
            isShow={isDisplayAgeValidation}
          />
        </div>
      )}
    </>
  );
};

export default Homepage;

/*
  <div className="sectionEndCTA pageEndCTA">
        <h3 className="sectionEndCTAHeader">Like what you see?</h3>
        <h2 className="sectionEndCTAHeader">Explore our collection</h2>
        <button className="sectionEndCTAButton"><a href="/Assets">View the collection</a></button>
      </div>
*/
