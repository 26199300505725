import React, { useEffect, useRef } from "react"
import { ChevronRight, X } from "react-bootstrap-icons"
import "../../Styles/FeaturedAssets.styles.scss"

const FeaturedAssetHighlightDetails = ({
  assetDetails,
  assetSelected,
  deselectAsset,
  USDFormatter,
}) => {
  const assetRef = useRef()
  const {
    Name,
    Description,
    OgCaskYield,
    YearBottled,
    Price,
    MSRP,
    AnnualizedRet,
    Files: MetaplexLink,
  } = assetDetails

  const handleClickOutside = (e) => {
    if (e.target && assetRef.current?.contains(e.target)) {
      return
    }
    deselectAsset()
  }

  useEffect(() => {
    if (assetSelected) {
      document.addEventListener("mousedown", handleClickOutside)
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          handleClickOutside(e)
        }
      })
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("keydown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("keydown", handleClickOutside)
    }
  }, [assetSelected])

  return (
    <div className='featuredAssetSelctedWrapper'>
      <X className='featuredAssetSelectedEsc' />
      <div className='featuredAssetSelctedContent' ref={assetRef}>
        <h2>{Name}</h2>
        <p>{Description}</p>
        <div className='featuredAssetSelectedBottleDetails'>
          <div className='featuredAssetSelectedBottleDetail'>
            Amount Bottled:{" "}
            <span className='featuredAssetSelectedBottleInfo'>
              {OgCaskYield || "n/a"}
            </span>
          </div>
          <div className='featuredAssetSelectedBottleDetail'>
            Year released:{" "}
            <span className='featuredAssetSelectedBottleInfo'>
              {YearBottled || "n/a"}
            </span>
          </div>
          <div className='featuredAssetSelectedBottleDetail'>
            Original MSRP:{" "}
            <span className='featuredAssetSelectedBottleInfo'>
              {MSRP ? USDFormatter.format(MSRP) : "n/a"}
            </span>
          </div>
          <div className='featuredAssetSelectedBottleDetail'>
            Current market value:{" "}
            <span className='featuredAssetSelectedBottleInfo'>
              {Price ? USDFormatter.format(Price) : "n/a"}
            </span>
          </div>
          <div className='featuredAssetSelectedBottleDetail'>
            Annualized return:{" "}
            <span className='featuredAssetSelectedBottleInfo'>
              {`${AnnualizedRet}%` || "n/a"}
            </span>
          </div>
        </div>
        {MetaplexLink ? (
          <div className='featuredAssetHighlightCTASection'>
            <button className='featuredAssetHighlightCTAButton'>
              <a href={MetaplexLink} target='_blank' rel='noreferrer'>
                Buy Now
              </a>
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default FeaturedAssetHighlightDetails

/*
        <div className="featuredAssetHighlightCTASection">
          <button className="featuredAssetHighlightCTAButton"><a href="#">Buy Now</a></button>
          <button className="featuredAssetHighlightCTAText"><a href="/Assets">Explore the collection<ChevronRight /></a></button>
        </div>
*/
