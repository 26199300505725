import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import AssetsPage from "./Pages/AssetsPage";
import Privacy from "./Pages/Privacy";
import TermsConditions from "./Pages/TermsConditions";

const Router = () => {
  const ScrollTopWrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      if (location.hash === "") {
        document.documentElement.scrollTo(0, 0);
      }
    }, [location.hash]);
    return children;
  };

  return (
    <ScrollTopWrapper>
      <Routes>
        <Route exact path="/" element={<Homepage />}></Route>
        <Route path="/assets" element={<AssetsPage />}></Route>

        <Route path="/privacy" element={<Privacy />}></Route>

        <Route path="/termsconditions" element={<TermsConditions />}></Route>
      </Routes>
    </ScrollTopWrapper>
  );
};

export default Router;
