
const insureContent = {
  header: "Insure & Secure",
  subHeader: "Our services",
  description: "BAXUS' number one priority is the safety and security of the valuable assets with which it is being entrusted. All assets submitted to BAXUS are insured and stored in temperature controlled vaults around the world. Each BAXUS facility is monitored 24/7 to ensure the safety and security of the valuable assets inside.",
  isForm: false,
  cta: "",
  ctaLink: "",
  imgSrc: "https://d241vrp4b4n7qa.cloudfront.net/Assets/insure_hero.png",
}

export default insureContent