import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Grid, X } from "react-bootstrap-icons";
import Asset from "@baxus/shared/dist/Components/Asset";
import Loader from "@baxus/shared/dist/Components/Loader";
import { USDFormatter } from "@baxus/shared/dist/Utils/Utils";
import FeaturedAssets from "../Components/FeaturedAssets/FeaturedAssets";
import "../Styles/AssetsPage.styles.scss";
import Filter from "./Filter";
import { FilterCircle } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { filter } from "domutils";
const AssetsPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [allAssets, setAllAssets] = useState([]);
  const [temp, setTemp] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState();
  const [isAssetSelected, setIsAssetSelected] = useState(false);
  const pageSize = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [isfilter, setIsFilter] = useState(false);

  console.log("location: ", location);
  useEffect(() => {
    setAllAssets([location.state]);
  }, [location.state]);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [selectedFilter, setSelectedFilter] = useState({
    price: [],
    key: [],
  });
  // console.log("selectedFilter------", selectedFilter);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [filterPriceData, setFilterPriceData] = useState([]);
  const allAssetDataHandler = async () => {
    try {
      axios
        .get(`${process.env.REACT_APP_ENDPOINT}/assets/all?pageSize=10`, {
          params: { pageSize: pageSize, pageIdx: pageSize * pageNumber },
        })
        .then((res) => {
          console.log("responseAll******", res.data);
          setAllAssets(res.data);
          setFilterPriceData(res.data);
          // setFilterAgeData(res.data);
          // setFilterStrengthData(res.data);
          // setFilterSizeData(res.data);
          setLoading(false);
        })
        .catch((e) => {
          console.log("Error fetching featured assets", e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isfilter) {
      // console.log("selectedFilter: ", selectedFilter?.price);
      // console.log("selectedFilter: ", selectedFilter?.key);
      var result = filterPriceData.filter(function (e) {
        return selectedFilter?.key.every(function (a) {
          return selectedFilter?.price?.includes(e[a]);
        });
      });
      setAllAssets(result);
    } else {
      setAllAssets(filterPriceData);
    }
  }, [selectedFilter, isfilter]);

  useEffect(() => {
    allAssetDataHandler();
  }, [pageNumber]);

  const changePagination = (direction) => {
    let updatedPageNumber =
      direction === "inc" ? pageNumber + 1 : pageNumber - 1; //in case of classic pagination
    // setPageNumber(pageNumber++) //in case of infinite scroll
    setPageNumber(updatedPageNumber);
  };

  // useEffect(() => {
  //   let filterObjPrice;
  //   let filterPbjAge;
  //   let filterObjStrength;
  //   let filterObjFunSize;
  //   if (priceFilter.isActive) {
  //     const filterFun = allAssets.filter((data) => {
  //       return parseFloat(data?.price) === parseFloat(priceFilter.price);
  //     });
  //     filterObjPrice = filterFun;
  //   }
  //   if (ageFilter.isActive) {
  //     const filterFunage = allAssets.filter((data) => {
  //       return parseFloat(data?.age) === parseFloat(ageFilter.price);
  //     });
  //     filterPbjAge = filterFunage;
  //   }
  //   if (strengthFilter.isActive) {
  //     const filterFunstrength = allAssets.filter((data) => {
  //       return parseFloat(data?.abv) === parseFloat(strengthFilter.price);
  //     });
  //     filterObjStrength = filterFunstrength;
  //   }
  //   if (sizeFilter.isActive) {
  //     const filterFunsize = allAssets.filter((data) => {
  //       return parseFloat(data?.bttl_size) === parseFloat(sizeFilter.price);
  //     });
  //     filterObjFunSize = filterFunsize;
  //   }
  //   // const mergeAllFilter = filterObjPrice
  //   //   .concat(filterPbjAge)
  //   //   .concat(filterObjStrength)
  //   //   .concat(filterObjFunSize);
  //   // setAllAssets(mergeAllFilter);
  //   if (
  //     !priceFilter.isActive &&
  //     !ageFilter.isActive &&
  //     !strengthFilter.isActive &&
  //     !sizeFilter.isActive
  //   ) {
  //     setAllAssets(filterPriceData);
  //   }
  // }, [priceFilter, ageFilter, strengthFilter, sizeFilter]);

  const selectAsset = (assetDetails) => {
    setSelectedAsset(assetDetails);
    setIsAssetSelected(true);
  };

  const renderAssetModalBody = () => {
    const {
      name,
      bttl_size,
      cover,
      desc_long,
      OgCaskYield,
      time_created,
      price,
      abv,
      age,

      Files: MetaplexLink,
    } = selectedAsset;
    return (
      <div className="assetSelectedBodyWrapper">
        <X
          className="featuredAssetSelectedEsc"
          onClick={() => setIsAssetSelected(false)}
        />
        <div className="assetSelectedBodyContentWrapper">
          <div className="featuredAssetContentWrapper assetSelectedBodyImage">
            <div className="featuredAssetImgWrapper">
              <img src={cover} alt={name} />
            </div>
          </div>
          <div className="featuredAssetSelctedWrapper assetSelectedBodyContent">
            <div className="featuredAssetSelctedContent">
              <h2>{name}</h2>
              <p>{desc_long}</p>
              <div className="featuredAssetSelectedBottleDetails">
                <div className="featuredAssetSelectedBottleDetail">
                  Amount Bottled:{" "}
                  <span className="featuredAssetSelectedBottleInfo">
                    {OgCaskYield || "n/a"}
                  </span>
                </div>
                <div className="featuredAssetSelectedBottleDetail">
                  Year released:{" "}
                  <span className="featuredAssetSelectedBottleInfo">
                    {time_created || "n/a"}
                  </span>
                </div>
                <div className="featuredAssetSelectedBottleDetail">
                  Size:{" "}
                  <span className="featuredAssetSelectedBottleInfo">
                    {`${bttl_size}` || "n/a"}
                  </span>
                </div>
                <div className="featuredAssetSelectedBottleDetail">
                  ABV:{" "}
                  <span className="featuredAssetSelectedBottleInfo">
                    {`${abv}` || "n/a"}
                  </span>
                </div>
                <div className="featuredAssetSelectedBottleDetail">
                  Age:{" "}
                  <span className="featuredAssetSelectedBottleInfo">
                    {`${age}` || "NAS"}
                  </span>
                </div>
              </div>
              {price && (
                <div className="featuredAssetHighlightCTASection">
                  {price ? (
                    <h4 className="featuredAssetHighlightCTASectionPrice">
                      {USDFormatter.format(price)}
                    </h4>
                  ) : (
                    <></>
                  )}

                  <button className="featuredAssetHighlightCTAButton">
                    <a href={price} target="_blank" rel="noreferrer">
                      Buy Now
                    </a>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const allAssetsList = useMemo(() => {
    return allAssets.map((asset) => {
      return (
        <>
          <Asset
            assetDetails={asset}
            key={asset.axu_id}
            selectAsset={selectAsset}
          />
        </>
      );
    });
  }, [allAssets]);

  return (
    <div className="assetsPageWrapper  page-first-section">
      <div className="assetsSplashWrapper">
        <FeaturedAssets />
      </div>
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row container-fluid">
              <div
                className="col-lg-2 col-md-3 col-sm-3 col-xs-4 filterSection"
                id="mySidenav"
              >
                {state.mobileView ? (
                  <>
                    {!open && (
                      <FilterCircle
                        style={{
                          color: "#000",
                          background: "#dd9c27",
                          borderRadius: "100%",
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                          marginTop: "1rem",
                        }}
                        onClick={() => setOpen(true)}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Filter
                      setSelectedFilter={(data) => setSelectedFilter(data)}
                      selectedFilter={selectedFilter}
                      setIsFilter={setIsFilter}
                    />
                  </>
                )}
                {open ? (
                  <>
                    <X
                      className="featuredAssetSelectedEsc"
                      onClick={() => setOpen(false)}
                      style={{ cursor: "pointer" }}
                    />
                    <Filter
                      setSelectedFilter={(data) => setSelectedFilter(data)}
                      selectedFilter={selectedFilter}
                      setIsFilter={setIsFilter}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-10 col-md-9 col-sm-9 col-xs-8">
                <div className="assetsPageAllAssetsWrapper">
                  {allAssetsList}
                </div>
              </div>
            </div>
            {/* <div className="assetsPageAllAssetsWrapper">{allAssetsList}</div> */}
          </>
        )}
      </>
      <Modal
        centered
        show={isAssetSelected}
        size="xl"
        className="assetModalContainer"
        onHide={() => setIsAssetSelected(false)}
      >
        <div className="assetSelectedWrapper">
          <Modal.Body>{isAssetSelected && renderAssetModalBody()}</Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default AssetsPage;
