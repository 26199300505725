
const tokenizationContent = {
  header: "Tokenize",
  subHeader: "Our services",
  description: "BAXUS assets are minted on the Solana blockchain as a unique indisputable proof of ownership of the underlying asset that is displayed and described in the NFT metadata.",
  isForm: false,
  cta: "",
  ctaLink: "",
  imgSrc: "https://d241vrp4b4n7qa.cloudfront.net/Assets/tokenization_hero.png",
}

export default tokenizationContent