import React, { useState, useEffect } from "react";
import "./filter.css";

import { ArrowRepeat, FilterCircle } from "react-bootstrap-icons";
import axios from "axios";
const country = [
  {
    name: "Australia",
  },
  {
    name: "Japan",
  },
  {
    name: "India",
  },
  {
    name: "UK",
  },
  {
    name: "Russia",
  },
  {
    name: "USA",
  },
];
const region = [
  {
    name: "Highlands",
  },
  {
    name: "Highlands",
  },
  {
    name: "Highlands",
  },
  {
    name: "Highlands",
  },
];
const bottler = [
  {
    name: "Amrut",
  },
  {
    name: "Ardmore",
  },
  {
    name: "Arran",
  },
  {
    name: "Auchrosik",
  },
];
export default function Filter({
  setSelectedFilter,
  selectedFilter,
  setIsFilter,
}) {
  const [filterDataList, setFilterDataList] = useState([]);
  const pageSize = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [checked, setChecked] = useState(false);
  const [priceFilter, setPriceFilter] = useState([]);
  const [ageFilter, setAgeFilter] = useState([]);
  const [strengthFilter, setStrengthFilter] = useState([]);
  const [sizeFilter, setSizeFilter] = useState([]);

  const filterDataHandler = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/assets/all?pageSize=10`,
        {
          params: { pageSize: pageSize, pageIdx: pageSize * pageNumber },
        }
      );
      if (res.status === 200) {
        var result = res.data.reduce((unique, o) => {
          if (!unique.some((obj) => obj.price === o.price)) {
            unique.push(o);
          }

          return unique;
        }, []);
        var resultage = res.data.reduce((unique, o) => {
          if (!unique.some((obj) => obj.age === o.age)) {
            unique.push(o);
          }

          return unique;
        }, []);
        var resultabv = res.data.reduce((unique, o) => {
          if (!unique.some((obj) => obj.abv === o.abv)) {
            unique.push(o);
          }

          return unique;
        }, []);
        var resultsize = res.data.reduce((unique, o) => {
          if (!unique.some((obj) => obj.bttl_size === o.bttl_size)) {
            unique.push(o);
          }

          return unique;
        }, []);

        setPriceFilter(result);
        setAgeFilter(resultage);
        setStrengthFilter(resultabv);
        setSizeFilter(resultsize);
        setFilterDataList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    filterDataHandler();
  }, []);

  const _inFilter = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked === true) {
      setIsFilter(checked);
      setSelectedFilter({
        price: [...selectedFilter.price, parseFloat(value)],
        key: [...selectedFilter.key, name],
      });
    } else {
      if (selectedFilter.price.length > 1) {
        setSelectedFilter({
          price: selectedFilter.price.filter(
            (item) => item !== parseFloat(value)
          ),
          key: [...selectedFilter.key, name],
        });
        setIsFilter(true);
      } else {
        setSelectedFilter({
          price: [],
          key: [],
        });
        setIsFilter(false);
      }
    }
  };

  return (
    <>
      <div className="filterSection">
        <div className="filterSection1">
          <ArrowRepeat
            style={{
              color: "#000",
              background: "#dd9c27",
              borderRadius: "3px",
            }}
          />
          &nbsp;&nbsp;
          <h5>Clear Filter</h5>
        </div>

        <h6>Price</h6>
        <div className="scrollBarDiv">
          {priceFilter &&
            priceFilter.map((data, i) => {
              return (
                <div className="checkBoxSection">
                  <input
                    type="checkbox"
                    id={`distillery${i}`}
                    aria-label="Checkbox for following text input"
                    className="checked"
                    onChange={_inFilter}
                    name="price"
                    value={data?.price}
                  />
                  &nbsp;&nbsp;
                  <label for={`distillery${i}`} id={`iddd${i}`}>
                    {data?.price}
                  </label>
                </div>
              );
            })}
        </div>

        <h6>Age</h6>
        <div className="scrollBarDiv">
          {ageFilter &&
            ageFilter.map((data, i) => {
              return (
                <div className="checkBoxSection">
                  <input
                    type="checkbox"
                    id={`age${i}`}
                    aria-label="Checkbox for following text input"
                    className="checked"
                    onChange={_inFilter}
                    name="age"
                    value={data?.age}
                  />
                  &nbsp;&nbsp;
                  <label for={`age${i}`} id={`iddd${i}`}>
                    {data?.age}
                  </label>
                </div>
              );
            })}
        </div>
        <h6>Strength</h6>
        <div className="scrollBarDiv">
          {strengthFilter &&
            strengthFilter.map((data, i) => {
              return (
                <div className="checkBoxSection">
                  <input
                    type="checkbox"
                    id={`strenght${i}`}
                    aria-label="Checkbox for following text input"
                    className="checked"
                    style={{ cursor: "pointer" }}
                    onChange={_inFilter}
                    name="strength"
                    value={data?.abv}
                  />
                  &nbsp;&nbsp;
                  <label
                    style={{ cursor: "pointer" }}
                    for={`strenght${i}`}
                    id={`iddd${i}`}
                  >
                    {data?.abv}
                  </label>
                </div>
              );
            })}
        </div>
        <h6>Size</h6>
        <div className="scrollBarDiv">
          {sizeFilter &&
            sizeFilter.map((data, i) => {
              return (
                <div className="checkBoxSection">
                  <input
                    type="checkbox"
                    id={`size${i}`}
                    aria-label="Checkbox for following text input"
                    className="checked"
                    onChange={_inFilter}
                    name="size"
                    value={data?.bttl_size}
                  />
                  &nbsp;&nbsp;
                  <label for={`size${i}`} id={`iddd${i}`}>
                    {data?.bttl_size}
                  </label>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
