const verificationContent = {
  header: "Authenticate",
  subHeader: "Our services",
  description: "All bottles and assets submitted to BAXUS undergo extensive authentication using a cutting edge machine learning technology, which is trained from wine and spirits experts who personally inspect each bottle prior to authentication.",
  isForm: false,
  cta: "",
  ctaLink: "",
  imgSrc: "https://d241vrp4b4n7qa.cloudfront.net/Assets/verification_hero.png",
}

export default verificationContent