import React from "react"

const Privacy = () => {
  return (
    <div className='legalPageWrapper container'>
      <div className='legalPageHeaderWrapper'>
        <h1 className='legalPageHeader'>PRIVACY POLICY</h1>
        <h4 className='legalPageHeader'>Last Updated: 1/2/2022</h4>
      </div>
      <div className='legalPageContent'>
        <p>
          We at BAXUS CORP. (“Baxus,” “we,” “us,” or “our”) have created this
          privacy policy (this “Privacy Policy”) because we know that you care
          about how information you provide to us is used and shared. This
          Privacy Policy describes how we process the information we collect via
          our digital marketplace platform and related services that are made
          available to you through Baxus (“Platform”), as well as through our
          website located at Baxus.co (the “Site”).{" "}
        </p>
        <h4 className='legalPageSectionHeader'>
          Description of Users and Acceptance of Terms
        </h4>
        <p className='legalPageSectionContent'>
          This Privacy Policy applies to visitors to the Site, who view only
          publicly-available content (“Visitors”), and individuals who have
          signed up to access and use our Platform (“Registered Users”).
        </p>
        <p className='legalPageSectionContent'>
          By visiting our Site, Visitors are agreeing to the terms of this
          Privacy Policy and the accompanying Terms of Use.
        </p>
        <p className='legalPageSectionContent'>
          By clicking “I AGREE” or otherwise manifesting assent to this Privacy
          Policy, each Registered User is agreeing to the terms of this{" "}
        </p>

        <h4 className='legalPageSectionHeader'>
          Privacy Policy, and the accompanying Terms of Use.
        </h4>
        <p className='legalPageSectionContent'>
          Capitalized terms not defined in this Privacy Policy shall have the
          meaning set forth in our Terms of Use.
        </p>
        <h4 className='legalPageSectionHeader'>
          The Information We Collect and/or Receive
        </h4>
        <p className='legalPageSectionContent'>
          In the course of operating the Site and the Platform, and/or
          interacting with you, Baxus will collect (and/or receive) the
          following types of information.{" "}
        </p>
        <p className='legalPageSectionSubheader'>• Contact Information</p>

        <p className='legalPageSectionContent'>
          When you contact us through the “Contact Us” feature on the Site, or
          in any other way, you will be asked to provide certain information,
          including but not limited to, name, e-mail address, phone number, and
          any other information you are contacting us about (collectively,
          “Contact Information”). The Contact Information is used to provide the
          requested service or information and to contact you for purposes of
          direct marketing of our current and future services.{" "}
        </p>
        <p className='legalPageSectionSubheader'>• Account Information</p>

        <p className='legalPageSectionContent'>
          In order to sign up to access and use our Platform, you will have to
          create an account on our Platform. In connection with opening an
          account on our Platform, we collect account credentials such as
          username and password, and other registration information
          (collectively, “Account Information”). You will also have to link your
          Wallet to your account. We use the Account Information to process the
          creation of your account, including to verify your identity, and to
          manage your account, including your transactions.
        </p>

        <p className='legalPageSectionSubheader'>• Transaction Information</p>

        <p className='legalPageSectionContent'>
          When you make, or attempt to make, a purchase, sale or other
          transaction through our Platform, we may collect certain information
          from you, including your name, age, shipping address, email address,
          phone number, and the nature and subject of the transaction. We refer
          to this information as “Transaction Information.” We use the
          Transaction Information that we collect generally to facilitate
          transactions between Registered Users, through our Platform (including
          providing you with invoices and/or transaction confirmations).
          Additionally, we use this Transaction Information to: communicate with
          you; screen our transactions for potential risk or fraud; comply with
          applicable laws, regulations and guidelines regarding identity
          verification; and when in line with the preferences you have shared
          with us, provide you with information or advertising relating to our
          products or services. We use third-party payment processing providers
          to process your payment-related information. Our third-party payment
          processing providers will collect from you, process and store your
          payment-related information including credit card information and/or
          bank account information in accordance with the terms and conditions
          of their privacy policies and terms of use. Baxus does not collect,
          store or process any payment-related information.
        </p>
        <p className='legalPageSectionSubheader'>• Other Information</p>

        <p className='legalPageSectionContent'>
          In addition to the Contact Information, Account Information, and
          Transaction Information (other than payment-related information), we
          may collect or receive additional information (collectively, the
          “Other Information”). Such Other Information may include:
        </p>

        <p className='legalPageSectionContentBullet'>
          • From Your Activity.In an ongoing effort to improve the Site and the
          Platform, we automatically collect certain information when you visit
          the Site. This information consists of IP addresses, browser type and
          language, referring and exit pages and URLs, date and time, amount of
          time spent on particular pages, what sections of the Site and the
          Platform you visit, Solana address, wallet type, and similar
          information concerning your use of the Site and the Platform
          (“Activity Information”).
        </p>

        <p className='legalPageSectionContentBullet'>
          • From Cookies.We also collect Activity Information by using “cookie”
          technology. Cookies are small packets of data that a website stores on
          the hard drive of your computer or mobile device to “remember”
          information about your visit. We may use session cookies (which expire
          once you close your web browser) and persistent cookies (which stay on
          your computer/device until you delete them). If you do not want us to
          place a cookie on your hard drive, you may be able to turn that
          feature off on your computer or mobile device. Please consult your
          Internet browser's documentation for information on how to do this and
          how to delete persistent cookies. However, if you decide not to accept
          cookies from us, the Site may not function properly.
        </p>
        <p className='legalPageSectionContentBullet'>
          • Third-Party Analytics. We may use one or more third-party analytics
          services to evaluate your use of the Site and the Platform, compile
          reports on activity (based on their collection of IP addresses,
          Internet service provider, browser type, operating system and
          language, referring and exit pages and URLs, data and time, amount of
          time spent on particular pages, what sections of the Site and the
          Platform you visit, number of links clicked while on the Site and the
          Platform, search terms and other similar usage data), and analyze
          performance metrics. These third parties use cookies and other
          technologies to help analyze and provide us the data. By accessing and
          using the Site and/or the Platform, you consent to the processing of
          data about you by these analytics providers in the manner and for the
          purposes set out in this Privacy Policy. For more information on these
          third parties, including how to opt out from certain data collection,
          please visit the sites below. Please be advised that if you opt out of
          any service, you may not be able to use the full functionality of the
          Site and the Platform.
        </p>

        <p className='legalPageSectionContent'>
          For Google Analytics, please visit https://www.google.com/analytics
        </p>

        <h4 className='legalPageSectionHeader'>
          Information Collected from Third Party Companies
        </h4>
        <p className='legalPageSectionContent'>
          We may receive personal information and other information about you
          from third party companies that provide wallets that you can link
          through our Platform (“Third Party Information”). Such third party
          companies may provide us with your Solana address and certain other
          information you choose to share with them. We will combine this Third
          Party Information with the other information we collect about you and
          from you pursuant to this Privacy Policy.
        </p>
        <h4 className='legalPageSectionHeader'>
          How We Use and Share Your Information
        </h4>
        <p className='legalPageSectionContent'>
          You authorize us to use the Contact Information, Account Information,
          Third Party Information, and Transaction Information (other than
          payment-related information), and Other Information (collectively, the
          "Information") to provide our Site, Platform and our services to you,
          solicit your feedback, inform you about our products and services and
          those of our third-party marketing partners, and to improve the Site
          and the Platform. Also, we may use and share the Information as
          described below.
        </p>
        <p className='legalPageSectionContentBullet'>
          • Agents, Providers and Related Third Parties. We may engage other
          companies and individuals to perform certain business-related
          functions on our behalf. Examples may include providing hosting
          services, technical assistance, order fulfillment, customer service,
          database management/backup services, billing and debt collecting
          services, and marketing assistance. These other companies will have
          access to the Information only as necessary to perform their functions
          and to the extent permitted by law. We may also share your Information
          with any of our parent companies, subsidiaries, or other companies
          under common control with us.
        </p>
        <p className='legalPageSectionContentBullet'>
          • Aggregated Information. In an ongoing effort to better understand
          users of the Site and the Platform, we may analyze the Information in
          aggregate form in order to operate, maintain, manage, and improve the
          Site and the Platform. This aggregate information does not identify
          you personally. We may use this aggregate information for marketing
          purposes. We may share this aggregate information with our affiliates,
          agents, business and business partners, and other third parties. We
          may also disclose aggregated user statistics in order to describe the
          Site, the Platform and our products to current and prospective
          business partners and to other third parties for other lawful
          purposes.
        </p>
        <p className='legalPageSectionContentBullet'>
          • Business Transfers. In the event of a merger, dissolution,
          reorganization or similar corporate event, or the sale of all or
          substantially all of our assets, we expect that your Information,
          including personal information, would be transferred to the surviving
          entity in a merger or the acquiring entity. All such transfers shall
          be subject to our commitments with respect to the privacy and
          confidentiality of such personal information as set forth in this
          Privacy Policy.
        </p>
        <p className='legalPageSectionContentBullet'>
          • Legal Requirements. To the extent permitted by law, we may also
          disclose the Information: (i) when required by law, court order, or
          other government or law enforcement authority or regulatory agency; or
          (ii) whenever we believe that disclosing such Information is necessary
          or advisable, for example, to protect the rights, property, or safety
          of Baxus or others.
        </p>
        <h4 className='legalPageSectionHeader'>
          Accessing and Modifying Personal Information and Communication
          Preferences
        </h4>
        <p className='legalPageSectionContent'>
          You may access, review, and make changes to your personal information
          by contacting us at{" "}
          <a href='mailto:info@baxus.co' target='_blank' rel='noreferrer'>
            info@baxus.co
          </a>
          . In addition, you may manage your receipt of marketing and
          non-transactional communications by clicking on the "unsubscribe" link
          located on the bottom of any Baxus marketing email. We will use
          commercially reasonable efforts to process such requests in a timely
          manner. You should be aware, however, that it is not always possible
          to completely remove or modify information in our subscription
          databases. You cannot opt out of receiving transactional e-mails
          related to the Platform and your account.
        </p>
        <h4 className='legalPageSectionHeader'>
          How We Protect Your Information
        </h4>
        <p className='legalPageSectionContent'>
          Baxus takes very seriously the security and privacy of the personal
          information that it collects pursuant to this Privacy Policy.
          Accordingly, we implement reasonable security measures designed to
          protect your personal information from loss, misuse and unauthorized
          access, disclosure, alteration and destruction, taking into account
          the risks involved in processing and the nature of such data, and to
          comply with applicable laws and regulations. Please understand,
          however, that no security system is impenetrable. We cannot guarantee
          the security of our databases or the databases of the third parties
          with which we may share your information (as permitted herein), nor
          can we guarantee that the information you supply will not be
          intercepted while being transmitted over the Internet. In particular,
          e-mail sent to us may not be secure, and you should therefore take
          special care in deciding what information you send to us via e-mail.
        </p>
        <h4 className='legalPageSectionHeader'>Links to Third-Party Sites</h4>
        <p className='legalPageSectionContent'>
          The Site and the Platform may, from time to time, contain links to
          external websites. Baxus encourages you to review the privacy and
          security policies of any externally linked websites that may be
          accessed through the Site. Baxus assumes no responsibility or
          liability for the information collection and disclosure practices of
          any external websites that a user can access through the Site. Please
          check the privacy policies of these external websites before you
          submit any personal information to them.
        </p>
        <h4 className='legalPageSectionHeader'>
          Important Notice to All Non-U.S. Residents
        </h4>
        <p className='legalPageSectionContent'>
          Our servers are located in the United States. Please be aware that
          your Information, including your personal information, may be
          transferred to, processed, maintained, and used on computers, servers,
          and systems located outside of your state, province, country, or other
          governmental jurisdiction where the privacy laws may not be as
          protective as those in your jurisdiction. If you are located outside
          the United States and choose to use Site and/or the Platform, you do
          so at your own risk.
        </p>
        <h4 className='legalPageSectionHeader'>California Privacy Rights</h4>
        <p className='legalPageSectionContent'>
          Pursuant to Section 1798.83 of the California Civil Code, residents of
          California have the right to obtain certain information about the
          types of personal information that companies with whom they have an
          established business relationship (and that are not otherwise exempt)
          have shared with third parties for direct marketing purposes during
          the preceding calendar year, including the names and addresses of
          those third parties, and examples of the types of services or products
          marketed by those third parties. If you wish to submit a request
          pursuant to Section 1798.83, please contact Baxus via email at{" "}
          <a href='mailto:info@baxus.co' target='_blank' rel='noreferrer'>
            info@baxus.co
          </a>
          .
        </p>
        <h4 className='legalPageSectionHeader'>DO NOT TRACK</h4>
        <p className='legalPageSectionContent'>
          Baxus does not respond to “Do Not Track” settings or other related
          mechanisms on our Site and Platform at this time.
        </p>
        <h4 className='legalPageSectionHeader'>Nevada Privacy Rights</h4>
        <p className='legalPageSectionContent'>
          If you are a resident of Nevada, you have the right to opt-out of the
          sale of certain personal information to third parties. You can
          exercise this right by contacting us at{" "}
          <a href='mailto:info@baxus.co' target='_blank' rel='noreferrer'>
            info@baxus.co
          </a>{" "}
          with the subject line “Nevada Do Not Sell Request” and providing us
          with your name and the email address associated with your account.
          Please note that we do not currently sell your personal information as
          sales are defined in Nevada Revised Statutes Chapter 603A.
        </p>
        <h4 className='legalPageSectionHeader'>Children</h4>
        <p className='legalPageSectionContent'>
          Baxus does not knowingly collect or receive personal information from
          children under the age of 18 through the Site and the Platform. If you
          are under the age of 18, please do not provide any personal
          information through the Site and the Platform. We encourage parents
          and legal guardians to monitor their children's Internet usage and to
          help enforce this Privacy Policy by instructing their children to
          never provide any personal information without their permission. If
          you have reason to believe that a child under the age of 18 has
          provided personal information to Baxus, please contact us at{" "}
          <a href='mailto:info@baxus.co' target='_blank' rel='noreferrer'>
            info@baxus.co
          </a>
          , and we will endeavor to delete that information from our databases.
        </p>
        <h4 className='legalPageSectionHeader'>
          Changes to this Privacy Policy
        </h4>
        <p className='legalPageSectionContent'>
          This Privacy Policy is effective as of the date stated at the top of
          this Privacy Policy. We may change this Privacy Policy from time to
          time, and will post any changes on the Site and the Platform as soon
          as they go into effect. By visiting the Site, and/or accessing and/or
          using the Platform after we make any such changes to this Privacy
          Policy, you are deemed to have accepted such changes. Please be aware
          that, to the extent permitted by applicable law, our use of the
          information collected is governed by the Privacy Policy in effect at
          the time we collect the information. Please refer back to this Privacy
          Policy on a regular basis.
        </p>
        <h4 className='legalPageSectionHeader'>Contact Us</h4>
        <p className='legalPageSectionContent'>
          If you have any questions about this Privacy Policy or to report a
          privacy issue, please contact us in one of the following ways:
        </p>
        <p className='legalPageSectionContent'>
          Email:{" "}
          <a href='mailto:info@baxus.co' target='_blank' rel='noreferrer'>
            info@baxus.co
          </a>
        </p>
        <p>Copyright 2022 BAXUS CORP. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Privacy
